














import { AnyObject } from '@movecloser/front-core'
import { DashmixIconName, DashmixBreadcrumbsProps } from '@movecloser/ui-core'
import { Component, Vue } from 'vue-property-decorator'

import { EditModeLayout } from '../../shared/components/EditModeLayout'
import { initBreadcrumbs } from '../../root/helpers/breadcrumbs'
import { Inject } from '../../../backoffice'

import { BannersForm } from '../components/BannersForm.vue'
import { GeneralSettingsForm } from '../components/GeneralSettingsForm.vue'
import { ProductCategoriesLevelZeroSettingsForm } from '../components/ProductCategoriesLevelZeroSettingsForm.vue'
import { InitSearchForm } from '../components/InitSearchForm.vue'
import { ISettingsRepository, SettingOptions, SettingRepositoryType } from '../contracts'
import SettingsForm from '../components/SettingsForm.vue'
import { IForm } from '../contracts/form'
import FreeDeliveryEyeCatcher from '../components/FreeDeliveryEyeCatcher.vue'
import ProductRecommendationsForm from '../components/productRecommendationsForm.vue'
import ExtraPromoDescriptionForm from '../components/ExtraPromoDescriptionForm.vue'

@Component({
  name: 'EditSetting',
  components: {
    EditModeLayout
  }
})
export class EditSetting extends Vue {
  @Inject(SettingRepositoryType)
  protected settingsRepository!: ISettingsRepository

  public readonly icons = DashmixIconName

  public loading = true
  public isSaving = false
  public payload: AnyObject | AnyObject[] = {}
  public formConfig?: IForm | AnyObject = {}

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.all.title')}`,
          target: { name: 'settings.config.all' }
        },
        {
          label: `${this.$t(`settings.${this.type}`)}`,
          target: { name: `settings.config.${this.type}` }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get formComponent () {
    const forms: IForm[] = this.$root.$options.configuration?.byFile('settings') || []
    const form = forms.filter(item => {
      return item.name === this.type
    })
    if (form.length > 0) {
      this.formConfig = form[0]
      return SettingsForm
    }
    switch (this.type) {
      case 'banners':
        return BannersForm
      case 'general':
        return GeneralSettingsForm
      case 'freeDeliveryEyeCatcher':
        return FreeDeliveryEyeCatcher
      case 'productCategoriesLevelZero':
        return ProductCategoriesLevelZeroSettingsForm
      case 'initSearch':
        return InitSearchForm
      case 'productRecommendations':
        return ProductRecommendationsForm
      case 'extraPromoDescription':
        return ExtraPromoDescriptionForm
      default:
        return null
    }
  }

  public get type () {
    if (this.$route.params.type) {
      return this.$route.params.type
    }
    if (this.$route.meta && this.$route.meta.type) {
      return this.$route.meta.type
    }
    return null
  }

  mounted () {
    this.loading = true

    // if (this.formComponent !== null) {
    //   this.payload = this.formComponent.defaultPayload()
    // }

    this.settingsRepository.getSetting(this.type).then(data => {
      if (typeof data === 'number' || typeof data === 'string') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.payload = data
      } else if (Array.isArray(data)) {
        this.payload = [
          ...(Array.isArray(data) ? data : Object.values(data))
        ]
      } else {
        this.payload = {
          ...this.payload,
          ...data as AnyObject
        }
      }
    }).catch(e => console.warn(e))
      .finally(() => {
        this.loading = false
      })
  }

  public onSubmit (additionalSettings?: SettingOptions) {
    this.isSaving = true

    this.settingsRepository.updateSetting<AnyObject>(this.type, this.payload, additionalSettings)
      .catch(e => console.warn(e))
      .finally(() => {
        this.isSaving = false
      })
  }
}

export default EditSetting
