



























import { Component, PropSync } from 'vue-property-decorator'

import { FormInput } from '../../shared/components/form'

import { ConfigForm } from './_abstract/ConfigForm.vue'
import { ExtraPromoDescriptionSettings } from '../contracts'

@Component<ExtraPromoDescriptionForm>({
  name: 'ExtraPromoDescriptionForm',
  components: { FormInput },
  created () {
    this.description = this.payload.description
    this.rules = this.payload.rules.toString()
  }
})
export class ExtraPromoDescriptionForm extends ConfigForm {
  @PropSync('formData', { type: Object, required: true })
  public payload!: ExtraPromoDescriptionSettings

  public rules = ''

  public description = ''

  public loading: boolean = false

  public onSubmit () {
    this.payload.rules = this.rules.split(',')
      .map(rules => Number(rules)) as number[]
    this.payload.description = this.description
    this.$emit('submit', { reproject: true, global: false })
  }
}

export default ExtraPromoDescriptionForm
